<template>
  <a-drawer
    v-model:visible="visible"
    :keyboard="false"
    :title="auditTypeMap[auditType]"
    width="700px"
  >
    <div class="content">
      <template v-if="auditType == 2">
        <div class="wrap">
          <div class="title">
            {{ dataInfo.resourceName }}
            <span
              class="status"
              :class="`audit-status-${dataInfo.auditStatus}`"
            >
              {{ auditStatusMap[dataInfo.auditStatus] }}
            </span>
          </div>
          <div class="user-form">
            <p
              class="user-form-item"
              v-for="item in dataInfo.datafieldJson"
              :key="item.key"
            >
              <span class="label">{{ item.name }}：</span>
              <template v-if="item.key == 'mobile'">
                {{ dataInfo.memberJson.mobile }}
              </template>
              <template v-else-if="item.key == 'department'">
                {{ dataInfo.memberJson.departmentName }}
              </template>
              <template v-else>
                <template
                  v-if="item.type == 2 || (item.type == 8 && item.isCheck == 2)"
                >
                  {{
                    JSON.parse(
                      dataInfo.memberJson.fields[item.key] || "{label:''}"
                    ).label
                  }}
                </template>
                <template v-else-if="item.type == 8 && item.isCheck == 1">
                  <template
                    v-for="val in JSON.parse(
                      dataInfo.memberJson.fields[item.key] || '[]'
                    )"
                    >{{ val.label }}&nbsp;&nbsp;
                  </template>
                  {{}}
                </template>
                <template v-else>
                  {{ dataInfo.memberJson.fields[item.key] }}
                </template>
              </template>
            </p>
          </div>
        </div>
      </template>
      <template v-else-if="auditType == 1">
        <div class="wrap">
          <div class="title">
            {{ dataInfo.planName }}
            <span
              class="status"
              :class="`audit-status-${dataInfo.auditStatus}`"
            >
              {{ auditStatusMap[dataInfo.auditStatus] }}
            </span>
          </div>
          <div class="user">
            <a-avatar
              class="avatar"
              :size="30"
              :src="dataInfo.initMemberPortrait"
            >
              <template #icon><UserOutlined /></template>
            </a-avatar>
            <div class="name">
              <OpenData type="userName" :openid="dataInfo.initMemberName" />
            </div>
            <div class="depart">
              <OpenData
                type="department"
                :openid="dataInfo.initMemberDepartmentName"
              />
            </div>
          </div>
        </div>
        <div class="wrap">
          <!-- 名称 -->
          <div class="task-name">
            {{ $t("CM_LB_Name") }}: {{ dataInfo.planName }}
          </div>
          <!-- 计划时间 -->
          <div class="time" v-if="dataInfo.planTime">
            {{ $t("ScheduledTime") }}: {{ dataInfo.planTime }}
          </div>
          <div class="time" v-else-if="dataInfo.trainingTime">
            {{ $t("ScheduledTime") }}: {{ dataInfo.trainingTime[0] }} ~
            {{ dataInfo.trainingTime[1] }}
          </div>
          <div class="action">
            <!-- 查看详情 -->
            <a-button type="link" @click="$refs.planDetailRef.show(dataInfo.planId)">{{
              $t("view_detail")
            }}</a-button>
            <!-- 修改培训负责人 -->
            <a-button
              type="link"
              v-if="dataInfo.auditStatus === 1 && activeKey == 2"
              @click="$refs.editTrainingLeaderRef.show(dataInfo.planId)"
              >{{ $t("RevisionOfTrainingLeaders") }}</a-button
            >
            <!-- 备注修改意见 -->
            <a-button
              v-if="dataInfo.auditStatus === 1 && activeKey == 2"
              type="link"
              @click="$refs.planDetailRef.show(dataInfo.planId, true)"
            >
              {{ $t('RemarksModifications') }}
            </a-button>
          </div>
        </div>
      </template>
      <template v-else-if="auditType == 3">
        <div class="wrap">
          <div class="title">
            {{ dataInfo.resourceName }}
            <span
              class="status"
              :class="`audit-status-${dataInfo.auditStatus}`"
            >
              {{ auditStatusMap[dataInfo.auditStatus] }}
            </span>
          </div>
          <div class="user">
            <a-avatar
              class="avatar"
              :size="30"
              :src="dataInfo.initMemberPortrait"
            >
              <template #icon><UserOutlined /></template>
            </a-avatar>
            <div class="name">
              <OpenData type="userName" :openid="dataInfo.initMemberName" />
            </div>
            <div class="depart">
              <OpenData
                type="department"
                :openid="dataInfo.initMemberDepartmentName"
              />
            </div>
          </div>
        </div>
        <div class="wrap">
          <!-- 名称 -->
          <div class="task-name">
            {{ $t("CM_LB_Name") }}: {{ dataInfo.resourceName }}
          </div>
          <div class="task-name">
            {{ $t("TransmissionUnit") }}: {{ dataInfo.materialAgencyName || '--' }}
          </div>
          <div class="action">
            <!-- 查看详情 -->
            <a-button type="link" @click="$refs.filingDetailRef.show(dataInfo)">{{
              $t("view_detail")
            }}</a-button>
          </div>
        </div>
      </template>
      <template v-else-if="auditType == 4">
        <div class="wrap">
          <div class="title">
            {{ dataInfo.meetingName }}
            <span
                class="status"
                :class="`audit-status-${dataInfo.auditStatus}`"
            >
              {{ auditStatusMap[dataInfo.auditStatus] }}
            </span>
          </div>
          <div class="user">
            <a-avatar
                class="avatar"
                :size="30"
                :src="dataInfo.initMemberPortrait"
            >
              <template #icon><UserOutlined /></template>
            </a-avatar>
            <div class="name">
              <OpenData type="userName" :openid="dataInfo.initMemberName" />
            </div>
            <div class="depart">
              <OpenData
                  type="department"
                  :openid="dataInfo.initMemberDepartmentName"
              />
            </div>
          </div>
        </div>
        <div class="wrap">
          <!-- 名称 -->
          <div class="task-name">
            {{ $t("PlaceClassRoomName") }}: {{ dataInfo.meetingName }}
          </div>
          <div class="task-name">
            {{ $t("AppointmentTime") }}: {{ dateFormat(dataInfo.reservationStart,"YYYY/MM/DD HH:mm:ss")}} ~  {{ dateFormat(dataInfo.reservationEnd,"YYYY/MM/DD HH:mm:ss")}}
          </div>
          <div class="task-name">
            {{ $t("ReservationName") }}: {{ dataInfo.reservationName || '--' }}
          </div>
          <div class="task-name">
            {{ $t("ContactNumber") }}: {{ dataInfo.reservationMobile || '--' }}
          </div>
          <div class="task-name">
            {{ $t("ApplicationDescription") }}: {{ dataInfo.reservationContent || '--' }}
          </div>
        </div>
      </template>
      <!-- 在线课程 -->
      <course
        v-if="auditType === 5"
        ref="courseRef"
        :dataInfo="dataInfo"
        :setting="setting"
        @on-success="hideDrawer(1)"
      />
      <div class="flow">
        <a-steps v-model:current="currentStep" direction="vertical">
          <a-step disabled v-for="(item, i) in flowList" :key="i">
            <template #icon>
              <div class="icon">
                {{ i + 1 }}
              </div>
            </template>
            <!-- 发起-->
            <template #title v-if="item.receiptDetailId === 'startEvent'">
              {{ $t("Launch") }}
            </template>
            <!-- 审核 -->
            <template #title v-else>
              <div>
                {{ $t("Audits") }}
                <!-- 人通过即可 -->
                <span class="tag">
                  {{
                    item.auditMethod === 1
                      ? $t("NumPersonCanPass", { num: 1 })
                      : $t("NeedToPassAll")
                  }}
                </span>
              </div>
              <div
                class="status"
                :class="`audit-status-${item.auditStatus}`"
                v-if="item.showStatus"
              >
                {{ auditStatusMap[item.auditStatus] }}
              </div>
            </template>
            <template #description>
              <!-- 发起 -->
              <div class="member" v-if="item.receiptDetailId === 'startEvent'">
                <div class="member-item">
                  <a-avatar
                    class="avatar"
                    :size="30"
                    :src="dataInfo.initMemberPortrait"
                  >
                    <template #icon><UserOutlined /></template>
                  </a-avatar>
                  <div class="name">
                    <OpenData
                      type="userName"
                      :openid="dataInfo.initMemberName"
                    />
                  </div>
                  <div class="time">
                    {{ dateFormat(dataInfo.initTime) }}
                  </div>
                </div>
              </div>
              <!-- 审核 -->
              <div class="member" v-else>
                <div
                  class="member-item"
                  v-for="(el, index) in item.receiptNodeUsers"
                  :key="index"
                >
                  <a-avatar
                    class="avatar"
                    :size="30"
                    :src="el.auditMemberPortrait"
                  >
                    <template #icon><UserOutlined /></template>
                  </a-avatar>
                  <div class="name">
                    <OpenData type="userName" :openid="el.auditMemberName" />
                  </div>
                  <div class="audit">
                    <div class="audit-status">
                      {{ el.auditStatus ? auditStatusMap[el.auditStatus] : "" }}
                      {{ el.auditTime ? dateFormat(el.auditTime) : "" }}
                    </div>
                    <!-- 审核意见 -->
                    <div class="audit-remark" v-if="el.auditDesc">
                      {{ $t("ApprovalOpinions") }}: {{ el.auditDesc }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </a-step>
          <a-step disabled>
            <template #icon>
              <div class="icon">
                {{ currentStep }}
              </div>
            </template>
            <!-- 结束 -->
            <template #title> {{ $t("Ends") }} </template>
          </a-step>
        </a-steps>
      </div>
    </div>
    <div class="footer">
      <a-space>
        <template v-if="isNeedAudit">
          <!-- 通过 -->
          <a-button
            type="primary"
            @click="$refs.auditOpinionModalRef.show(2)"
            >{{ $t("CM_Pass") }}</a-button
          >
          <!-- 驳回 -->
          <a-button @click="$refs.auditOpinionModalRef.show(3)">{{
            $t("TurnDown")
          }}</a-button>
        </template>
        <template v-if="dataInfo.auditStatus === 1 && activeKey == 1">
          <!-- 撤回 -->
          <a-popconfirm @confirm="handleRetract">
            <template #title>
              <div>{{ $t("ConfirmType", { type: $t("Retract") }) }}</div>
              <p>{{ $t("RetractTip") }}</p>
            </template>
            <a-button type="primary">{{ $t("Retract") }}</a-button>
          </a-popconfirm>
        </template>
        <!-- 取消 -->
        <a-button @click="hideDrawer">{{ $t("CM_Cancel") }}</a-button>
      </a-space>
    </div>
    <!-- 审核意见 -->
    <auditOpinionModal
      ref="auditOpinionModalRef"
      :setting="setting"
      :auditType="auditType"
      @on-success="hideDrawer(1)"
      @set-course-period="(data) => $refs.courseRef.openPeriod(true, data)"
    />
    <!-- 培训计划 - 修改培训负责人 -->
    <editTrainingLeader ref="editTrainingLeaderRef" />
    <!-- 培训计划 - 查看详情 -->
    <planDetail ref="planDetailRef" />
    <!-- 培训材料备案 - 查看详情 -->
    <filingDetail ref="filingDetailRef" />
  </a-drawer>
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { ref, reactive, getCurrentInstance } from "vue";
import { auditTypeMap, auditStatusMap } from "@/utils/business.js";
import {
  getPlanDetail,
  getAuditNodeDetail,
  submitAudit,
  auditInformationDetail,
  getFilingDetail,
  getMeetingReservationDetail,
  getCourseDetail,
} from "@/api/auditCenter";
import { dateFormat } from "@/utils/tools.js";
import OpenData from "@/components/OpenData.vue";
import auditOpinionModal from "./auditOpinionModal";
import editTrainingLeader from "./plan/editTrainingLeader.vue";
import planDetail from "./plan/detail.vue";
import filingDetail from "./filing/detail.vue";
import course from './course/index.vue';

const emit = defineEmits(["emit"]);
const { t: $t } = useI18n();
const { proxy } = getCurrentInstance();
const visible = ref(false);
const activeKey = ref("1");
const currentStep = ref(10);
const dataInfo = ref({});
const isNeedAudit = ref(false); // 是否需要我审批
const flowList = ref([]);
const setting = ref({});
const auditType = ref(1);
const methodMap = {
  1: getPlanDetail,
  2: auditInformationDetail,
  3: getFilingDetail,
  4: getMeetingReservationDetail,
  5: getCourseDetail,
}
async function show(data, key, flag = false) {
  activeKey.value = key;
  isNeedAudit.value = flag;
  auditType.value = data.auditType;

  const method = methodMap[auditType.value];
  if (method) {
    const res = await method(data.resourceId);
    if (res.ret === 0) dataInfo.value = res.data;
  }
  dataInfo.value.resourceName = data.resourceName;
  dataInfo.value.auditStatus = data.auditStatus;
  dataInfo.value.initMemberPortrait = data.initMemberPortrait;
  dataInfo.value.initMemberName = data.initMemberName;
  dataInfo.value.initMemberDepartmentName =
    data.initMemberDepartmentName || "";
  dataInfo.value.initTime = data.initTime;

  const result = await getAuditNodeDetail(data.receiptId);
  if (result.ret === 0) {
    const { auditreceiptnode, ...params } = result.data;
    setting.value = params;
    let list = auditreceiptnode || [];
    list.forEach((item, i) => {
      item.showStatus = dataInfo.value.auditStatus <= 2;
      if (i > 1) {
        item.showStatus = list[i - 1].auditStatus === 2;
      }
    });
    flowList.value = list;
    currentStep.value = flowList.value.length + 1;
  }
  visible.value = true;
}
async function handleRetract() {
  const params = {
    auditStatus: 4,
    receiptIds: [setting.value.receiptId],
  };
  const res = await submitAudit(params);
  if (res.ret === 0) {
    proxy.$message.success($t("CM_Success"));
    hideDrawer(1);
  } else {
    proxy.$message.error($t("CM_Fail"));
  }
}
function hideDrawer(type) {
  visible.value = false;
  if (type === 1) emit("complete");
}

defineExpose({
  show,
});
</script>
<style lang="less" scoped>
.content {
  ::v-deep(.wrap) {
    padding: 20px 0;
    border-bottom: 1px solid #f5f5f5;
    &:first-child {
      padding-top: 0;
    }
    .title {
      position: relative;
      max-width: 60%;
      font-size: 18px;
      color: #333;
      .status {
        display: inline-block;
        padding: 2px 6px;
        border-radius: 4px;
        // background-color: orange;
        vertical-align: 2px;
        font-size: 12px;
        color: #fff;
      }
    }
    .user {
      display: flex;
      align-items: center;
      margin-top: 10px;
      .name {
        margin: 0 10px;
      }
    }
    .user-form {
      padding-top: 20px;
      padding-left: 16px;
      &-item {
        margin-bottom: 4px;
        line-height: 20px;
        color: #777;
        .label {
          color: #333;
        }
      }
    }
  }
  .action {
    margin-top: 20px;
  }
  .flow {
    padding: 20px 0;
    .icon {
      background: #1890ff;
      color: #fff;
      border-radius: 50%;
      font-size: 18px;
    }
    .tag {
      display: inline-block;
      padding: 0 8px;
      line-height: 28px;
      background-color: #f5f5f5;
      font-size: 12px;
    }
    .member {
      min-height: 40px;
      &-item {
        display: flex;
        margin: 10px 0;
        color: #333;
        line-height: 30px;
        .name {
          margin: 0 10px;
        }
        .audit {
          flex: 1;
        }
      }
    }
    .status {
      display: inline-block;
      line-height: 20px;
      padding: 2px 6px;
      border-radius: 4px;
      font-size: 12px;
      color: #fff;
    }
  }
}
.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  padding: 10px 0;
  text-align: center;
  background-color: #fff;
  border-top: 1px solid #ccc;
}
</style>
